import React from "react";

// Core Components
import Header from "../components/Header";
import Footer from "../components/Footer";

import Back from "assets/img/top_back.jpg";

function Lesson() {
  return (
    <>
      <div className="wrapper">
        <div id="wrap">
          <Header />
          <div id="main_area" style={{ backgroundImage: `url(${Back})` }}>
            <div id="main_text2">
              <p>
                プロフィール
                <br />
                東邦音楽短期大学卒業後専攻科編入（ピアノ）
                <br />
                中学校音楽教員資格取得
                <br />
                ヤマハ音楽教室講師
                <br />
                楽友社(音楽事務所)ブライダルオルガン・ピアノプレイヤー
              </p>
              <p>&nbsp;</p>
              <p>レッスンについて</p>
              <p>
                体験レッスン無料
                <br />
                1レッスン1時間　週1回　聴音を含む場合もあり
                <br />
                小さいお子様でご希望があれば30分週2回も可
                <br />
                大人の方　ご都合の良い日時を予約
              </p>
              <p>お月謝</p>
              <p>
                月4回　各30分レッスン　4000円
                <br />
                月4回　各45分レッスン　6000円
                <br />
                月4回　各60分レッスン　8000円
                <br />
                フリー(隔週、毎回予約など)は1レッスン2000円
              </p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Lesson;
