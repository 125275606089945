import React, { useState } from "react";

// Core Components
import Header from "../components/Header";
import Footer from "../components/Footer";

import Back from "assets/img/top_back.jpg";

import ReCAPTCHA from "react-google-recaptcha-enterprise";
function onChange(value) {
  console.log("Captcha value:", value);
}
const recaptchaRef = React.createRef();

function Contact() {
  const [name, setName] = useState("");
  const [kana, setKana] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [text, setText] = useState("");

  const handleChange = (event) => {
    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;
      case "kana":
        setKana(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      case "text":
        setText(event.target.value);
        break;
      default:
        console.log("key not found");
    }
  };

  const clearForm = (textarea) => {
    setName("");
    setKana("");
    setEmail("");
    setPhone("");
    setText("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(name);
    console.log(kana);
    console.log(email);
    console.log(phone);
    console.log(text);
    if (name && kana && email && phone && text) {
      const token = await recaptchaRef.current.executeAsync();
      const body = {
        name: name,
        kana: kana,
        email: email,
        phone: phone,
        text: text,
        recaptcha_token: token,
      };
      const response = await postContact(body);
      console.log(response);
      clearForm();
      alert("お問い合わせを送信しました。");
    } else {
      alert("全ての項目を入力してください。");
    }
  };

  const postContact = async (body) => {
    const url =
      "https://nc5ypkha92.execute-api.ap-northeast-1.amazonaws.com/prod/contact";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    return await response.json();
  };

  return (
    <>
      <div className="wrapper">
        <div id="wrap">
          <Header />
          <div id="main_area" style={{ backgroundImage: `url(${Back})` }}>
            <div id="main_text2">
              <p>お問い合わせ</p>
              <form onSubmit={handleSubmit}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onChange}
                  size="invisible"
                />
                <input type="hidden" name="action" value="contact" />
                <table className="table">
                  <tbody>
                    <tr>
                      <th>お名前</th>
                      <td>
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          size="30"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>ふりがな</th>
                      <td>
                        <input
                          type="text"
                          name="kana"
                          value={kana}
                          onChange={handleChange}
                          size="30"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>メールアドレス</th>
                      <td>
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          size="30"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>お電話番号</th>
                      <td>
                        <input
                          type="tel"
                          name="phone"
                          value={phone}
                          onChange={handleChange}
                          size="30"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>お問い合わせ内容</th>
                      <td>
                        <textarea
                          name="text"
                          cols="30"
                          rows="5"
                          onChange={handleChange}
                          value={text}
                          required
                        ></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <input type="submit" />
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Contact;
