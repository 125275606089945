import React from "react";

// Core Components
import Header from "../components/Header";
import Footer from "../components/Footer";

import Back from "assets/img/top_back.jpg";

function Index() {
  return (
    <>
      <div className="wrapper">
        <div id="wrap">
          <Header />
          <div id="main_area" style={{ backgroundImage: `url(${Back})` }}>
            <div id="main_text">
              <h2>よしだピアノ教室は杉並区浜田山にあるピアノ教室です。</h2>
              <p>
                私は57歳です。子育て時代を除いて学生時代から含めて約２５年ピアノ教師をしています。
                経験を積む中で今まで感じてきたことはレッスンで何を伝えることが一番大切なことか？
                それはピアノが弾けるということを楽しめるかどうか。だとおもいます。
                初めてピアノの練習を始めるとき指づかい、指のかたち、などなど細かい約束ごとにとら
                われたレッスンではなく弾くことが楽しいと感じられるレッスンを心がけています。
                もちろん指づかい、かたちは大切なことですがそれはレッスンをしていくうちに自然と身に
                つくものです。
                そして専門家を目指す方は別ですが大人になっても自分で楽譜を読みピアノが弾けるようになっ
                ていただける力をつけていくお手伝いが出来れば、というのがわたしの目指すレッスンです。
                今まで一緒に勉強してきた方々もピアノ以外の楽器にも挑戦されたり、クラッシックだけではなく
                ポピュラーな曲を弾いたり、さまざまな楽しみ方でレッスンしています。
                大人の方では以前ピアノは習ったことがある、とか好きな曲だけ練習したい、という方も
                楽しくレッスンしましょう。今は楽譜も充実していていろいろなジャンルの音楽も練習できます。
              </p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Index;
