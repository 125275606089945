import React from "react";

// Core Components
import Header from "../components/Header";
import Footer from "../components/Footer";

import Back from "assets/img/top_back.jpg";

function Access() {
  return (
    <>
      <div className="wrapper">
        <div id="wrap">
          <Header />
          <div id="main_area" style={{ backgroundImage: `url(${Back})` }}>
            <div id="main_text2">
              <p>場所</p>
              <p>京王井の頭線 浜田山駅</p>
              <p>浜田山会館近く</p>
              <p>詳しくはお問い合わせください</p>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3240.8597996123076!2d139.630059!3d35.680454!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeb5cfccd407f608!2z5p2J5Lim5Yy65b255omAIOa1nOeUsOWxseS8mumkqA!5e0!3m2!1sja!2sjp!4v1396807768081"
                width="300"
                height="200"
                frameBorder="0"
                style={{ border: 0 }}
              ></iframe>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Access;
