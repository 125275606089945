import React from "react";
import { Link } from "react-router-dom";

import Logo from "assets/img/logo.jpg";
import Lesson from "assets/img/menu_lesson.jpg";
import Access from "assets/img/menu_access.jpg";
import Contact from "assets/img/menu_contact.jpg";

function Header() {
  return (
    <>
      <header className="header">
        <div id="head">
          <h1>
            <Link to="/index">
              <img src={Logo} alt="よしだピアノ教室"></img>
            </Link>
          </h1>
          <div id="navi_area">
            <ul>
              <li>
                <Link to="/lesson">
                  <img
                    src={Lesson}
                    alt="レッスンについて"
                    width="120"
                    height="72"
                  />
                </Link>
              </li>
              <li>
                <Link to="/access">
                  <img
                    src={Access}
                    alt="交通アクセス"
                    width="120"
                    height="72"
                  />
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <img
                    src={Contact}
                    alt="お問い合わせ"
                    width="120"
                    height="72"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
