import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div id="foot">
          <div id="foot_in">
            <ul>
              <li>
                <Link to="/index">ホーム</Link>
              </li>
              <li>
                <Link to="/lesson">レッスンについて</Link>
              </li>
              <li>
                <Link to="/access">交通アクセス</Link>
              </li>
              <li>
                <Link to="/contact">お問い合せ</Link>
              </li>
            </ul>
            <p id="copy">
              Copyright (C) 2021 よしだピアノ教室. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
