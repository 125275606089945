import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import "./App.css";

import Index from "./views/Index";
import Lesson from "./views/Lesson";
import Access from "./views/Access";
import Contact from "./views/Contact";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Index {...props} />} />
        <Route path="/lesson" exact render={(props) => <Lesson {...props} />} />
        <Route path="/access" exact render={(props) => <Access {...props} />} />
        <Route
          path="/contact"
          exact
          render={(props) => <Contact {...props} />}
        />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
